<template>
  <div class="card mb-5 mb-xl-10" v-if="currentUser">
    <div class="card-header border-0 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">My account</h3>
      </div>
    </div>

    <div id="my_account" class="collapse show">
      <el-form
        id="my_account_form"
        class="form"
        :model="currentUser"
        ref="formRef"
      >
        <div class="card-body border-top p-9">
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">
              Name
            </label>

            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <el-form-item prop="name">
                  <input
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Name"
                    v-model="currentUser.name"
                  />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Email
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="student_id">
              <input
                type="email"
                name="email"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-model="currentUser.email"
              />
              </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Role
            </label>

            <div class="col-lg-8 fv-row">
              <el-form-item prop="role">
                <input
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Role"
                    v-model="currentUser.role"
                    disabled
                  />
                </el-form-item>
            </div>
          </div>

          <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-bold fs-6">
                Password
              </label>
              <div class="col-lg-8 fv-row">
                <el-form-item prop="password">
                    <input
                        type="password"
                        style="width:100%;"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Password"
                        v-model="password"
                    />
                </el-form-item>
              </div>
          </div>

          <div class="row mb-6">
              <label class="col-lg-4 col-form-label fw-bold fs-6">
                Confirm Password
              </label>
              <div class="col-lg-8 fv-row">
                <el-form-item prop="password">
                    <input
                        type="password"
                        style="width:100%;"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Password"
                        v-model="confirmPassword"
                    />
                </el-form-item>
              </div>
          </div>
        </div>

        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="button"
            id="kt_account_profile_details_submit"
            @click="submit()"
            class="btn btn-primary"
          >
            <span class="indicator-label"> Save Changes </span>
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/SmsStoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "my-account",
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const confirmPassword = ref('');
    const password= ref('');
    const store = useStore();
    const currentUser = store.getters.currentUser;    

    const submit = () => {
        let errorMessage = '';

        if(!currentUser.name || !currentUser.email ){
            errorMessage = "Please fill out all required fields";
        }
        else if((password.value !== '' || confirmPassword.value !== '') && password.value != confirmPassword.value){
            errorMessage = 'password and confirmed password are not matched';
        }

        if(errorMessage !== ''){
            Swal.fire({
                text: errorMessage,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            });

            return false;
        }
        else{
            loading.value = true;
            let userForm : any = { id: currentUser.id, name: currentUser.name, email : currentUser.email, password : password.value };
            store
            .dispatch(Actions.UPDATE_USER, userForm)
            .then(() => {
                Swal.fire({
                        text: "Account change successfully submitted!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                    }).then(() => {
                        router.push({ name: "dashboard" });
                    });
                
            })
            .catch(() => {
                    Swal.fire({
                    text: "Update Failed.",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Try again!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
            });

            /*
            ApiService.setHeader();  
            ApiService.put("user/"+currentUser.id, userForm)
                .then(()=>{
                        Swal.fire({
                        text: "Account change successfully submitted!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                    }).then(() => {
                        password.value= '';
                        confirmPassword.value = '';
                        store.dispatch(Actions.UPDATE_USER, currentUser);
                    });
                })
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('edit account error => '+response.data.errors);
                    Swal.fire({
                        text: "Failed to edit account",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
                */
        }
    };
    
    onMounted(() => {
      setCurrentPageBreadcrumbs("My Account",[]);
    });

    return {
      submit,
      formRef,
      currentUser,
      password,
      confirmPassword
    };
  },
});
</script>
