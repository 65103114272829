
import { defineComponent, onMounted, ref, } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/SmsStoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "my-account",
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const confirmPassword = ref('');
    const password= ref('');
    const store = useStore();
    const currentUser = store.getters.currentUser;    

    const submit = () => {
        let errorMessage = '';

        if(!currentUser.name || !currentUser.email ){
            errorMessage = "Please fill out all required fields";
        }
        else if((password.value !== '' || confirmPassword.value !== '') && password.value != confirmPassword.value){
            errorMessage = 'password and confirmed password are not matched';
        }

        if(errorMessage !== ''){
            Swal.fire({
                text: errorMessage,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            });

            return false;
        }
        else{
            loading.value = true;
            let userForm : any = { id: currentUser.id, name: currentUser.name, email : currentUser.email, password : password.value };
            store
            .dispatch(Actions.UPDATE_USER, userForm)
            .then(() => {
                Swal.fire({
                        text: "Account change successfully submitted!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                    }).then(() => {
                        router.push({ name: "dashboard" });
                    });
                
            })
            .catch(() => {
                    Swal.fire({
                    text: "Update Failed.",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Try again!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
            });

            /*
            ApiService.setHeader();  
            ApiService.put("user/"+currentUser.id, userForm)
                .then(()=>{
                        Swal.fire({
                        text: "Account change successfully submitted!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                    }).then(() => {
                        password.value= '';
                        confirmPassword.value = '';
                        store.dispatch(Actions.UPDATE_USER, currentUser);
                    });
                })
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('edit account error => '+response.data.errors);
                    Swal.fire({
                        text: "Failed to edit account",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
                */
        }
    };
    
    onMounted(() => {
      setCurrentPageBreadcrumbs("My Account",[]);
    });

    return {
      submit,
      formRef,
      currentUser,
      password,
      confirmPassword
    };
  },
});
